import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import RenderBlock from '../utils/render-block'
import { LoadMore } from '../components/icons.js'

class PageTemplate extends Component {

  componentDidMount() {
    document.querySelector('.header__logo span').innerHTML = this.props.data.wordpressPage.title === 'Home' ? 'EM Advisory' : this.props.data.wordpressPage.title 
  }

  render() {
    let page = this.props.data.wordpressPage
    let metaDescription = page.yoast_meta.find(x => x.name === 'description') ? page.yoast_meta.find(x => x.name === 'description').content : '';
    let ogImage = page.yoast_meta.find(x => x.name === 'og:image') ? page.yoast_meta.find(x => x.name === 'og:image').content.source_url : '';
    return (
      <>
        <SEO title={he.decode(page.yoast_title)} bodyClass={page.slug} description={metaDescription} ogImage={ogImage} />
        { page.acf.content_blocks_page && page.acf.content_blocks_page.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
        { page.acf.previous &&
          <section className='pagination'>
            <ul>
              <li><Link to={page.acf.previous.link}><LoadMore colour={'#000'} />{page.acf.previous.title}</Link></li>
              <li><Link to={page.acf.next.link}>{page.acf.next.title}<LoadMore colour={'#000'} /></Link></li>
            </ul>
          </section>
        }
      </>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query currentPageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      slug
      yoast_title
      yoast_meta {  
       name
       content
       property
      }
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_home {
            gallery {
              image {
                ...original
              }
              mobile_image {
                ...original
              }
              tagline
            }
          }
          ... on WordPressAcf_ouroffering {
            title
            content
            ouroffering {
              title
              image {
                ...original
              }
              content
            }
          }
          ... on WordPressAcf_basic {
            title
            content
          }
          ... on WordPressAcf_approach {
            title
            content
          }
          ... on WordPressAcf_people {
            title
            content
            people {
              name
              position
              profile_picture {
                ...original
              }
              bio
              linkedin
            }
          }
          ... on WordPressAcf_clients {
            title
            clients {
              logo {
                ...original
              }
              link
            }
          }
          ... on WordPressAcf_contact {
            office {
              title
              address
              phone
              email
              linkedin
              map {
                lat
                lng
              }
            }
            form_title
            confirmation_message
          }
        }
        previous {
          title
          link
        }
        next {
          title
          link
        }
      }
    }
  }
`

